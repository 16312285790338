import React from "react";
import { Table } from "react-bootstrap";

export const Hours = () => (
  <div className="mb-5">
    <h4>Hours of Operation</h4>
    <Table size="sm" borderless className="m-0">
      <tbody>
        <tr>
          <td style={{ textAlign: "right" }}>Sunday</td>
          <td style={{ textAlign: "left" }}>Closed</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right" }}>Monday</td>
          <td style={{ textAlign: "left" }}>8:30 am - 5:00pm</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right" }}>Tuesday</td>
          <td style={{ textAlign: "left" }}>Closed</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right" }}>Wednesday</td>
          <td style={{ textAlign: "left" }}>8:30 am - 5:00pm</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right" }}>Thursday</td>
          <td style={{ textAlign: "left" }}>8:30 am - 5:00pm</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right" }}>Friday</td>
          <td style={{ textAlign: "left" }}>8:30 am - 5:00pm</td>
        </tr>
        <tr>
          <td style={{ textAlign: "right" }}>Saturday</td>
          <td style={{ textAlign: "left" }}>7:30 am - 4:00pm</td>
        </tr>
      </tbody>
    </Table>
  </div>
);
