import React from 'react';
import { Container } from 'react-bootstrap';
import {
  About,
  CarShow,
  Contact,
  Copyright,
  Hours,
  Pricing,
  Splash,
  Stream,
} from "./components";

function App() {
  return (
    <>
      <Splash />
      <main>
        <About />
        <Pricing />
        <Stream />
      </main>
      <footer>
        <Container as="section" className="text-center">
          <Hours />
          <Contact />
          <Copyright />
        </Container>
      </footer>
    </>
  );
}

export default App;
